<template>
  <div
    class="site-analytics"
    :class="{ 'site-analytics--active': siteAnalyticsStore.selectedCamera }"
  >
    <div class="site-analytics__content">
      <v-expand-x-transition>
        <div
          v-if="siteAnalyticsStore.selectedCamera"
          class="site-analytics__player"
        >
          <nuxt-child />
        </div>
      </v-expand-x-transition>

      <div
        class="site-analytics__cameras d-flex"
        :class="{
          'pt-2': siteAnalyticsStore.selectedCamera,
          'flex-column': siteAnalyticsStore.selectedCamera,
          'flex-wrap': !siteAnalyticsStore.selectedCamera,
          'pa-3': !siteAnalyticsStore.selectedCamera,
        }"
      >
        <nuxt-link
          v-for="camera in siteAnalyticsStore.cameras"
          :key="camera.exid"
          :to="`/site-analytics/${camera.exid}`"
          tag="div"
          class="site-analytics__camera-container"
          @click.native="siteAnalyticsStore.selectCamera(camera.exid)"
        >
          <CameraCard
            :camera="camera"
            :token="accountStore.token"
            :absolute="false"
            show-exid
            :width="siteAnalyticsStore.selectedCamera ? 135 : 300"
            :highlighted="
              camera.exid === siteAnalyticsStore.selectedCamera?.exid
            "
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import CameraCard from "@evercam/shared/components/CameraCard"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"

export default Vue.extend({
  name: "SiteAnalytics",
  components: {
    CameraCard,
  },
  async asyncData() {
    await useSiteAnalyticsStore().fetchCameras()
  },
  computed: {
    ...mapStores(useSiteAnalyticsStore, useAccountStore),
  },
})
</script>

<style lang="scss">
@import "~@evercam/shared/styles/mixins";

.site-analytics {
  background: #212121;
  &__content {
    display: flex;
  }

  &__player {
    width: calc(100% - 180px);
  }

  &__cameras {
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    @include custom-scrollbar(#5d5d5d, #9b9b9b, #333);
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
    }
    .snapshot-card {
      box-shadow: 1px 2px 7px -2px rgba(0, 0, 0, 0.75);
      &--highlighted {
        box-shadow: 0 0 0 4px #629efc;
      }
      &__label {
        background: #3b3b3b;
        color: #f6f9ff;
      }
    }
  }
  &:not(.site-analytics--active) {
    .site-analytics__cameras {
      align-content: flex-start;
    }
    .snapshot-card {
      border: 1px solid #2a2a2a;
      .v-image {
        box-shadow: none !important;
      }
    }
    .snapshot-card__label {
      padding: 0.75em 1.5em !important;
      border-top: 1px solid #2a2a2a;
    }
  }
  &.site-analytics--active {
    .snapshot-card {
      margin: 0.5em 1.25em !important;
      .camera-card__label {
        font-size: 0.85em !important;
      }
    }
  }
  .e-bg-gray-900,
  .e-timeline svg {
    background: #212121 !important;
    .forbidden-interval {
      opacity: 0.8;
    }
  }
}
</style>
